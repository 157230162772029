define(() => {

  class simpleSlider {
    /**
     *
     * @param element
     * @param options
     */
    constructor(element, options) {
      if (!element)
        this.el = '.simpleSlider';
      else
        this.el = element;

      if (!(this.el instanceof HTMLElement)) {
        this.el = document.querySelector(this.el);
      }

      this.options = {
        links: '.simpleSlider_nav .simpleSlider_slide-nav-link',
        wrapper: '.simpleSlider_slide',
        prev: '.simpleSlider_buttonPrev',
        next: '.simpleSlider_buttonNext',
        slide: '.simpleSlider_slideItem',
        slideCaption: '.simpleSlider_slide-caption',
        slideNav: '.simpleSlider_slide-nav',
        loop: false,
        switchKey: true,
        hasSwipe: true
      };

      if (options)
        this.options = options;

      this.init();
    }

    /**
     * init
     */
    init() {
      if (this.el) {
        this.slides = this.el.querySelectorAll(this.options.slide);
        this.navigation = this.el.querySelector(this.options.slideNav);
        this.links = this.el.querySelectorAll(this.options.links);
        this.wrapper = this.el.querySelector(this.options.wrapper);

        this.prev = this.el.querySelector(this.options.prev);
        this.next = this.el.querySelector(this.options.next);
      }

      this.active = true;
      if (this.slides) {
        this.total = this.slides.length;
      }
      this.current = 0;

      this.moveSlide(0);
      if (this.options.hasSwipe) this.handleTouch();
      this.navigate();
    }

    /**
     * navigate
     */
    navigate() {
      let SELF = this;
      if (this.navigation) {
        for (let i = 0; i < this.links.length; ++i) {
          this.slideByNav(i);
        }
      }

      if (this.prev) this.slideByButton(this.prev, -1);
      if (this.next) this.slideByButton(this.next, 1);

      window.addEventListener('keydown', (e) => {
        SELF.checkSwitchKey(e, SELF.options.switchKey);
      });
    }

    checkSwitchKey(e, switchKey) {
      if (switchKey === true) {
        this.slideByKey(e);
      }
    }

    slideByKey(e) {
      if (e.keyCode === 37) {
        this.moveSlide(this.current - 1);
      }

      if (e.keyCode === 39) {
        this.moveSlide(this.current + 1);
      }
    }

    /**
     * slideByNav
     * @param index
     */
    slideByNav(index) {
      let SELF = this;
      this.links[index].addEventListener('click', (e) => {
        e.preventDefault();
        SELF.moveSlide(index);
      }, false);
    }

    /**
     * slideByButton
     * @param index
     */
    slideByButton(element, index = 0) {
      let SELF = this;

      element.addEventListener('click', (e) => {
        e.preventDefault();
        SELF.moveSlide(this.current + index);
      }, false);
    }

    /**
     * moveSlide
     * @param index
     */
    moveSlide(index = 0) {

      if (this.active) {
        if (!this.options.loop && (index < 0 || index >= this.total)) return;
        else {
          if (this.options.loop && index >= this.total)
            this.current = 0;
          else if (this.options.loop && index < 0)
            this.current = this.total - 1;
          else
            this.current = index;
        }

        if (!this.slides.length) return;
        this.wrapper.style.left = `-${this.slides[this.current].offsetLeft}px`;
        this.removeCurrent();
        this.addCurrent();

      }
    }

    /**
     * removeCurrent
     */
    removeCurrent() {
      for (let i = 0; i < this.total; i++) {
        this.slides[i].classList.remove('simpleSlider-current');
        if (this.links.length) this.links[i].classList.remove('simpleSlider-current');
      }
    }

    /**
     * addCurrent
     * @param index
     */
    addCurrent() {
      this.currentSlide = this.slides[this.current];
      this.slides[this.current].classList.add('simpleSlider-current');
      if (this.links.length) this.links[this.current].classList.add('simpleSlider-current');
    }

    /**
     * handleTouch
     */
    handleTouch() {
      let SELF = this;
      let touchstartX = 0;
      let touchstartY = 0;
      let touchendX = 0;
      let touchendY = 0;
      let distanceX = 0;
      let distanceY = 0;

      SELF.wrapper.addEventListener('touchstart', (event) => {
        touchstartX = event.touches[0].screenX;
        touchstartY = event.touches[0].screenY;
      }, false);

      SELF.wrapper.addEventListener('touchend', (event) => {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;

        distanceX = touchendX - touchstartX;
        distanceY = touchendY - touchstartY;


        if (Math.abs(distanceX) > Math.abs(distanceY)) { //swipe left right
          if (distanceX > 50) SELF.swipeRight();
          if (distanceX < -50) SELF.swipeLeft();
        }

      }, false);
    }

    /**
     * swipeRight
     */
    swipeRight() {
      this.moveSlide(this.current - 1);
    }

    /**
     * swipeLeft
     */
    swipeLeft() {
      this.moveSlide(this.current + 1);
    }
  }

  if (document.querySelector('.simpleSlider-auto')) return new simpleSlider('.simpleSlider-auto');
  return simpleSlider;

});
